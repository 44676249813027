$(function () {
  $('a[href^="#"]').on('click', function() {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    if ($('.userpoint_area').css('position') !== 'fixed') {
      var position =
        target.offset().top;
    } else {
      var position = target.offset().top;
    }
    $('html, body').animate(
      {
        scrollTop: position,
      },
      550,
      'swing'
    );
    return false;
  });  
});
//リスト作成
var prefList = ["animation01","animation02","animation03"]
var contactBtn = document.querySelectorAll(".contact-icon");
contactBtn.forEach(function(item) {
  item.addEventListener('mouseover', function() {
    // getRandomPref01(prefList);
    var getRandomPref = (list) =>{
      //取得する要素の番号を求める。list.length
      var prefListNum = Math.floor(Math.random() * list.length);
      item.classList.remove("animation01","animation02","animation03")
      item.classList.add(list[prefListNum]);
    }
    getRandomPref(prefList);
  });
});


